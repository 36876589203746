
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import {  ImageSettings } from "@/Settings/Settings";

export default Vue.extend({

  data: () => ({
    subcatImageUrl: ImageSettings.baseSubcatUrl,
  }),
  computed: {
    ...mapState("items", ["categories", "subCategories"]),
    ...mapState("store", ["selectedStoreDetails"]),
  },
  methods: {
    ...mapActions("items", ["getCategories", "getSubCategories"]),
    ...mapActions("cusService", ["getInAppMsg"]),
  },
  mounted() {
    /** Getting list of categories */
  
    
  },



});
